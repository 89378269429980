import { FlagProvider, type IConfig } from "@unleash/proxy-client-react";

import type { WithChildren } from "utils/types";

const config: IConfig = {
  url: import.meta.env.REACT_APP_FEATURE_FLAGS_URL || "not defined",
  clientKey:
    import.meta.env.REACT_APP_FEATURE_FLAGS_CLIENT_KEY || "not defined",
  refreshInterval: import.meta.env.PROD ? 5 * 60 : undefined,
  appName: "for whatever reason doesn't matter",
};

const FeatureFlagsProvider = ({ children }: WithChildren) => {
  return <FlagProvider config={config}>{children}</FlagProvider>;
};

export default FeatureFlagsProvider;
