import type { FC } from "react";
import type React from "react";
import { IntlProvider } from "react-intl";

import type { WithChildren } from "utils/types";

import newEnMessages from "../../translations/compiled/en-GB.json";
import newNbMessages from "../../translations/compiled/nb-NO.json";
import enMessages from "../../translations/deprecated/en.json";
import noMessages from "../../translations/deprecated/no.json";
import { useLang, type LanguageEnum } from "./I18nContextProvider";

export const allMessages = {
  "en-GB": enMessages,
  "nb-NO": noMessages,
};

function loadLocaleData(locale: string) {
  switch (locale) {
    case "nb-NO":
      return newNbMessages;
    default:
      return newEnMessages;
  }
}

const I18nProvider: FC<WithChildren> = ({ children }) => {
  const locale: LanguageEnum = useLang();

  // in case there is untranslated message, fallback it to english
  // const messages = fallbackTranslations(allMessages[locale], enMessages);

  const messages = loadLocaleData(locale);

  return (
    <IntlProvider
      locale={locale}
      messages={messages}
      defaultRichTextElements={{
        b: (chunks: React.ReactNode) => <b>{chunks}</b>,
        li: (chunks: React.ReactNode) => <li>{chunks}</li>,
        p: (chunks: React.ReactNode) => <p>{chunks}</p>,
        br: () => <br />,
      }}
      defaultLocale="en-GB"
    >
      {children}
    </IntlProvider>
  );
};

export default I18nProvider;
