import React, { createContext, useContext, useMemo, type FC } from "react";

import type { WithChildren } from "utils/types";

import { allMessages } from "./I18nProvider";

export enum LanguageEnum {
  EN = "en-GB",
  NO = "nb-NO",
}

const I18N_CONFIG_KEY =
  import.meta.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";
const initialLang = {
  selectedLang: LanguageEnum.EN,
};

function getConfig() {
  const savedLocale = localStorage.getItem(I18N_CONFIG_KEY);
  if (savedLocale) {
    try {
      const locale = JSON.parse(savedLocale);
      const isSupportedLocale = Object.keys(allMessages).includes(
        locale.selectedLang,
      );
      if (isSupportedLocale) {
        return locale;
      } else {
        localStorage.removeItem(I18N_CONFIG_KEY);
        return initialLang;
      }
    } catch (er) {
      console.error(er);
    }
  }
  return initialLang;
}

// Side effect
export function setLanguage(lang: LanguageEnum) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
  window.location.reload();
}

const I18nContext = createContext(initialLang);

export function useLang() {
  return useContext(I18nContext).selectedLang;
}

export const I18nConsumer = I18nContext.Consumer;

const MetronicI18nProvider: FC<WithChildren> = ({ children }) => {
  const lang = useMemo(getConfig, []);

  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>;
};

export default MetronicI18nProvider;
