import { useCallback, type FC } from "react";
import { Close } from "@mui/icons-material";
import { IconButton, Link } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useSnackbar } from "notistack";
import { FormattedMessage, useIntl } from "react-intl";

import { mapTheme } from "modules/advancedMap/styles/AdvMapThemeProvider";

const ReactQueryProvider: FC = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleError = useDefaultError();

  const handleSuccess = useCallback(() => {
    enqueueSnackbar(<FormattedMessage id="GLOBAL.SUCCESS_MESSAGE" />, {
      variant: "success",
      action: (key) => <SnackbarActionIcon action={() => closeSnackbar(key)} />,
      key: "success",
    });
  }, [enqueueSnackbar, closeSnackbar]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        onError: handleError,
        refetchOnWindowFocus: import.meta.env.PROD,
      },
      mutations: {
        onError: handleError,
        onSuccess: handleSuccess,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {import.meta.env.DEV && <ReactQueryDevtools />}
    </QueryClientProvider>
  );
};

export default ReactQueryProvider;

export const SnackbarActionIcon = ({ action }: { action: () => void }) => (
  <IconButton aria-label="delete" onClick={action}>
    <Close fontSize="small" />
  </IconButton>
);

export const useDefaultError = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const intl = useIntl();

  const handleError = useCallback(
    (error: any) => {
      const emailMessage = intl.formatMessage(
        {
          id: "REQUEST.ERROR.MESSAGE",
        },
        {
          email: (
            <Link
              variant="body2"
              href={`mailto:post@plugport.no?subject=Request ID (${error?.data?.RequestID})`}
              color={mapTheme.palette.text.hint}
            >
              post@plugport.no
            </Link>
          ),
        },
      );

      enqueueSnackbar(
        <span>
          {error?.data?.Message ||
            intl.formatMessage({ id: "GLOBAL.ERROR_MESSAGE" })}
          {error?.data?.RequestID &&
            ` (Request ID: ${error?.data?.RequestID}).`}
          <br />
          {error?.data?.RequestID && emailMessage}
        </span>,
        {
          variant: "error",
          action: (key) => (
            <SnackbarActionIcon action={() => closeSnackbar(key)} />
          ),
          key: "error",
        },
      );
    },
    [enqueueSnackbar, intl, closeSnackbar],
  );

  return handleError;
};
