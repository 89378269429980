import type React from "react";

import CookiesConsentModal from "./CookieConsetModal";
import { CookieConsentProvider } from "./CookiesConsentContext";

type CookieConsetWrapperProps = {
  children: React.ReactNode;
};

const CookieConsetWrapper = ({ children }: CookieConsetWrapperProps) => {
  // Don't show cookie consent modal in tests
  if (import.meta.env.MODE === "test") {
    return <>{children}</>;
  }

  return (
    <CookieConsentProvider>
      <CookiesConsentModal />
      {children}
    </CookieConsentProvider>
  );
};

export default CookieConsetWrapper;
