import { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";

import { useGetPermissions } from "api/generated/v2/system";
import FormikAutocomplete from "components/forms/mui/FormikAutocomplete";

const Roles = () => {
  const { formatMessage } = useIntl();
  const [orgType, setOrgType] = useState("");
  const [role, setRole] = useState("");

  const formik = useFormik({
    initialValues: { role: "" },
    onSubmit: () => handleSubmit(),
  });

  const { values } = formik;

  const { data: permissions } = useGetPermissions(
    {
      orgType,
      role,
    },
    { query: { enabled: !!orgType && !!role, keepPreviousData: false } },
  );

  const handleSubmit = () => {
    if (values.role) {
      setRole(values.role.split(":")[1]);
      setOrgType(values.role.split(":")[0]);
    }
  };

  const options = [
    {
      label: formatMessage({ defaultMessage: "System admin" }),
      value: "system:admin",
    },
    {
      label: formatMessage({ defaultMessage: "System standard" }),
      value: "system:standard",
    },
    {
      label: formatMessage({ defaultMessage: "Port owner admin" }),
      value: "portOwner:admin",
    },
    {
      label: formatMessage({ defaultMessage: "Port owner standard" }),
      value: "portOwner:standard",
    },
    {
      label: formatMessage({ defaultMessage: "Port control admin" }),
      value: "portControl:admin",
    },
    {
      label: formatMessage({ defaultMessage: "Port control standard" }),
      value: "portControl:standard",
    },
    {
      label: formatMessage({ defaultMessage: "Port service standard" }),
      value: "portService:standard",
    },
    {
      label: formatMessage({ defaultMessage: "Port service admin" }),
      value: "portService:admin",
    },
    {
      label: formatMessage({ defaultMessage: "Vessel owner admin" }),
      value: "vesselOwner:admin",
    },
    {
      label: formatMessage({ defaultMessage: "Vessel owner standard" }),
      value: "vesselOwner:standard",
    },
    {
      label: formatMessage({ defaultMessage: "Vessel user" }),
      value: "vesselOwner:vesseluser",
    },
  ];

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h4">
          <FormattedMessage defaultMessage="Role" />
        </Typography>
        <Typography variant="body1" sx={{ mt: 0.5 }}>
          <FormattedMessage defaultMessage="Describes a combination of an organisation type and role such as 'vessel owner:admin',  that determines who can do what in the system. Pick a role below to display relevant permissions." />
        </Typography>

        <FormikProvider value={formik}>
          <Form noValidate>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={3}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignContent="center"
                  alignItems="center"
                >
                  <FormikAutocomplete
                    options={options}
                    name={"role"}
                    label={formatMessage({ defaultMessage: "Role" })}
                    sx={{ width: "100%" }}
                  />
                  <Box>
                    <Button type="submit" variant="contained">
                      <FormattedMessage defaultMessage="Submit" />
                    </Button>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Paper>
      {permissions && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography variant="h4">
            <FormattedMessage defaultMessage="Permissions" />
          </Typography>

          <Stack sx={{ mt: 2 }} spacing={1} divider={<Divider />}>
            {permissions?.permissions?.map((permission) => {
              return (
                <Stack key={permission} direction="row" spacing={1}>
                  <Typography variant="body2" sx={{ minWidth: 160 }}>
                    {permission}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Paper>
      )}
    </>
  );
};

export default Roles;
