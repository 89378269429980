import React, { type FC } from "react";
import { css } from "@emotion/react";
import { GlobalStyles } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// TODO: REMOVE after refactor to MUIv5
export const theme = {
  colors: {
    white: "#FFFFFF",
    plugBlue: "#00A0D2",
    buttonBlue: "#0078B6",
    lightAccent: "#2D516C",
    dark: "#113147",
    darkAccent: "#1C374C",
    darkest: "#0B2234",
    textWhite: "#ffffff",
    textGrey: "#768c98",
    textGreyLight: "#F5F5F5",
    textGreyMedium: "#AEC1CB",
    textGreyDark: "#768C98",
    textTurquoise: "#7ddff2",
    connectedGreen: "#2ebd60",
    connectedGreenLight: "#81FFAD",
    notConnectedBlue: "#9ec6ea",
    alarmRed: "#9D2F42",
    textAlarmRed: "#FD3A5C",
    warningOrange: "#ff9446",
    okTeal: "#46afb8",
    okTealDark: "#218992",
    disconnectedGrey: "#444444",
    servicePurple: "#a762ff",
  },
  gradients: {
    alarmRed: "linear-gradient(90deg, #D94848 0%, #EE4545 100%)",
    warningOrange: "linear-gradient(270deg, #FFA551 0%, #FF9447 100%)",
    okTeal: "linear-gradient(90deg, #46AFB8 0%, #52CAD4 100%)",
    disconnectedGrey: "linear-gradient(90deg, #7B7B7B 0%, #8C8C8C 100%)",
    servicePurple: "linear-gradient(90deg, #A762FF 0%, #B67DFF 100%)",
  },
  fonts: {
    raleway: "Raleway",
    robotoCond: "Roboto Condensed",
    light: 300,
    regular: 400,
    bold: 700,
  },
};

export const mapTheme = createTheme({
  // in comment there is a reference to colors name used in designs (Figma)
  palette: {
    mode: "dark",
    primary: {
      main: "#0078B6", // buttonBlue
      dark: "#004F99", // darkBlue
      light: "#00A0D2", // plugBlue
    },
    secondary: {
      main: "#f50057",
      light: "#4EDFFE", // turquoise,
      dark: "#730099", // purple
    },
    background: {
      default: "#113147", // dark
      paper: "#1C374C", // darkAccent,
      light: "#2D516C", // lightAccent
      dark: "#0B2234", // Dark select
    },
    warning: {
      main: "#FF9446", // warningOrange
    },
    error: {
      main: "#9D2F42", // alarmRed
      light: "#FD3A5C", // textAlarmRed
    },
    success: {
      main: "#46AFB8", // okTeal
      light: "#2ebd60", // connectedGreen
      dark: "#218992",
    },
    info: {
      main: "#AEC1CB", // connectedGreenLight,
    },
    text: {
      secondary: "#AEC1CB", // textGreyMedium
      disabled: "#768C98", // textGrey, textGreyDark
      hint: "#F5F5F5", // textGreyLight
    },
  },
  typography: {
    // headerMd
    h1: {
      fontFamily: '"Raleway", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "1.25rem",
      fontWeight: 700,
      letterSpacing: ".05em",
    },
    // headerSm (header2)
    h2: {
      fontFamily: '"Raleway", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "1rem",
      fontWeight: 700,
      letterSpacing: "0.1em",
    },
    h3: {
      fontSize: ".75rem",
      fontWeight: 700,
      letterSpacing: "0.1em",
    },
    h4: {
      fontFamily: '"Raleway", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
      fontSize: "1.625rem",
    },
    subtitle1: {
      fontFamily: '"Raleway", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
    },
    // subheader
    subtitle2: {
      fontFamily: '"Raleway", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
      fontSize: "0.75rem",
    },
    body1: {
      fontFamily: '"Raleway", "Roboto", "Helvetica", "Arial", sans-serif',
      letterSpacing: "0.1em",
    },
    body2: {
      fontFamily: '"Raleway", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "0.75rem",
      letterSpacing: "0.1em",
    },
    // robotoCondenced2Bold
    data1: {
      fontFamily:
        '"Roboto Condensed", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
      fontSize: "0.75rem",
      letterSpacing: 0,
    },
    // robotoCondenced2Regular
    data2: {
      fontFamily:
        '"Roboto Condensed", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "0.75rem",
      fontWeight: 400,
      letterSpacing: 0,
    },
    // robotoCondenced1Bold
    data3: {
      fontFamily:
        '"Roboto Condensed", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 700,
      fontSize: "1rem",
      letterSpacing: 0,
    },
    caption: {
      fontFamily: '"Raleway", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    overline: {
      fontFamily: '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
    },
    button: {
      fontFamily: '"Raleway", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: "0.75rem",
      fontWeight: 700,
      letterSpacing: "0.1em",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: "small",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFab: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiRadio: {
      defaultProps: {
        size: "small",
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
    MuiList: {
      defaultProps: {
        dense: true,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },
  },
});

const inputGlobalStyles = (
  <GlobalStyles
    styles={css`
      #advanced-map-root-id * {
        ::-webkit-scrollbar {
          width: 12px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: ${mapTheme.palette.background.light};
          border-radius: 6px;
          border: 3px solid ${mapTheme.palette.background.default};
        }
        ::-webkit-scrollbar-track {
          background: ${theme.colors.dark};
        }
      }

      .drilldown-item-hover {
        transition: background-color 0.2s linear;

        &:hover {
          background-color: #1c374c !important;
        }
      }
    `}
  />
);

const AdvMapThemeProvider: FC = ({ children }) => {
  return (
    <ThemeProvider theme={mapTheme}>
      {inputGlobalStyles}
      {children}
    </ThemeProvider>
  );
};

export default AdvMapThemeProvider;
