import React, { type FC } from "react";
import { SnackbarProvider as SnackbarActualProvider } from "notistack";

const SnackbarProvider: FC = ({ children }) => {
  return (
    <SnackbarActualProvider preventDuplicate>{children}</SnackbarActualProvider>
  );
};

export default SnackbarProvider;
