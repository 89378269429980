import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { hasRole } from "components/stores/UserStore";

const ViewAsRedirect = () => {
  const { push } = useHistory();

  useEffect(() => {
    if (hasRole(["vesselOwner-standard", "vesselOwner-admin"])) {
      push("/port-calls");
    }
    if (hasRole(["vesselOwner-vesseluser"])) {
      push("/my-vessel");
    }
    if (
      hasRole([
        "portOwner-admin",
        "portOwner-standard",
        "system-admin",
        "system-standard",
      ])
    ) {
      push("/dashboard");
    }
    if (
      hasRole([
        "portService-admin",
        "portService-standard",
        "portControl-admin",
        "portControl-standard",
      ])
    ) {
      push("/map");
    }
  }, [push]);

  return <></>;
};

export default ViewAsRedirect;
