import { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";

import { useGetPermissions, useGetRoles } from "api/generated/v2/system";
import FormikAutocomplete from "components/forms/mui/FormikAutocomplete";

const Permissions = () => {
  const [permission, setPermission] = useState("");
  const { formatMessage } = useIntl();

  const formik = useFormik({
    initialValues: { permission: "" },
    onSubmit: () => handleSubmit(),
  });

  const { data: options } = useGetPermissions(
    { role: "", orgType: "" },
    {
      query: {
        select: (data) =>
          data?.permissions?.map((permission) => ({
            value: permission,
            label: permission,
          })),
      },
    },
  );

  const { values } = formik;

  const { data: roles } = useGetRoles(
    { permission },
    { query: { enabled: !!permission, keepPreviousData: false } },
  );

  const handleSubmit = () => {
    if (values.permission) {
      setPermission(values.permission);
    }
  };

  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h4">
          <FormattedMessage defaultMessage="Permissions" />
        </Typography>
        <Typography variant="body1" sx={{ mt: 0.5 }}>
          <FormattedMessage defaultMessage="Describes the level of access to a resource, i.e. what a user can do. By picking a resource and an action such as alarms:read', the page will display the relevant user roles." />
        </Typography>

        <FormikProvider value={formik}>
          <Form noValidate>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={3}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignContent="center"
                  alignItems="center"
                >
                  <FormikAutocomplete
                    options={options ?? []}
                    name={"permission"}
                    label={formatMessage({ defaultMessage: "Resource" })}
                    sx={{ width: "100%" }}
                  />
                  <Box>
                    <Button type="submit" variant="contained">
                      <FormattedMessage defaultMessage="Submit" />
                    </Button>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Paper>
      {permission && (
        <Paper sx={{ p: 2, mt: 2 }}>
          <Typography variant="h4">
            <FormattedMessage
              defaultMessage="Roles for {permission}"
              values={{ permission }}
            />
          </Typography>

          <Stack sx={{ mt: 2 }} spacing={1} divider={<Divider />}>
            {roles?.roles?.map((role) => {
              return (
                <Stack key={role.orgType} direction="row" spacing={1}>
                  <Typography variant="body2" sx={{ minWidth: 160 }}>
                    {role.orgType} : {role.role}
                  </Typography>
                </Stack>
              );
            })}
          </Stack>
        </Paper>
      )}
    </>
  );
};

export default Permissions;
