import { type FC } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import { Box, Button, Stack, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import ErrorBoundaryImg from "assets/backgrounds/error_boundary.jpg";
import PlugLogoWhite from "assets/logos/plug_logo_white.png";
import { FormattedMessage } from "react-intl";

import { history } from "../../utils/history";

const ErrorFallback: Sentry.FallbackRender = ({ resetError, eventId }) => {
  return (
    <Stack sx={{ width: "100vw", height: "100vh" }}>
      <Box
        display="flex"
        height="100%"
        style={{
          backgroundImage: `url(${ErrorBoundaryImg})`,
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Stack
          width="100%"
          alignItems="center"
          sx={{ textAlign: "center", color: "white", mt: 4 }}
        >
          <img
            style={{ width: 250, objectFit: "contain" }}
            alt="Plug"
            src={PlugLogoWhite}
          />
          <Typography variant="h1" fontWeight="boldest" mb={6}>
            <FormattedMessage id="GLOBAL.ERROR.TITLE" />
          </Typography>

          <Typography variant="h4">
            <FormattedMessage id="GLOBAL.ERROR.SUBTITLE" />
          </Typography>
          <Typography variant="h4" mt={3}>
            <FormattedMessage id="GLOBAL.ERROR.USER_ACTION" />
          </Typography>

          <Stack direction="row" alignItems="center" spacing={1} mt={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.reload()}
            >
              <FormattedMessage id="GLOBAL.ERROR.USER_ACTION.RELOAD" />
            </Button>
            <span>
              <FormattedMessage id="GLOBAL.ERROR.USER_ACTION_DIVIDER" />
            </span>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                resetError();
                history.push("/dashboard");
              }}
            >
              <FormattedMessage id="GLOBAL.ERROR.USER_ACTION.GO_TO_DASHBOARD" />
            </Button>
            <span>
              <FormattedMessage id="GLOBAL.ERROR.USER_ACTION_DIVIDER" />
            </span>
            <Button
              variant="contained"
              color="primary"
              onClick={() => Sentry.showReportDialog({ eventId })}
            >
              <FormattedMessage defaultMessage="Report feedback" />
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

const GlobalErrorBoundary: FC = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={ErrorFallback}
      onError={(error, componentStack, eventId) => {
        datadogLogs.logger.error("Global Error Boundary", {
          errorName: error.name,
          errorMessage: error.message,
          errorStack: error.stack,
          componentStack,
          eventId,
        });
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default GlobalErrorBoundary;
