import { createTheme, type ThemeOptions } from "@mui/material/styles";

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#004f99",
      light: "#4e7aca",
      dark: "#00286a",
      contrastText: "#f5f5f5",
    },
    secondary: {
      main: "#730099",
      dark: "#41006a",
      light: "#a642cb",
      contrastText: "#f5f5f5",
    },
    common: {
      teal: "rgba(70, 175, 184, 0.6)",
    },
    background: {
      default: "#f5f5f5",
      paper: "#ffffff",
      alert: {
        info: "#e9f5fe",
      },
    },
  },
  typography: {
    overline: {
      fontFamily:
        '"Roboto Condensed", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    fontFamily: '"Raleway", "Roboto", "Helvetica", "Arial", sans-serif',
    body2: {
      fontFamily:
        '"Roboto Condensed", "Roboto", "Helvetica", "Arial", sans-serif',
    },
    h1: {
      fontSize: "2rem",
      fontWeight: 600,
    },
    h2: {
      fontSize: "1.875rem",
      fontWeight: 400,
    },
    h3: {
      fontSize: "1.625rem",
    },
    h4: {
      fontSize: "1.375rem",
      fontWeight: 600,
    },
    h5: {
      fontSize: "1.375rem",
    },
    h6: {
      fontSize: "1.125rem",
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: "small",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFab: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiRadio: {
      defaultProps: {
        size: "small",
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: "md",
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiList: {
      defaultProps: {
        dense: false,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        // TODO: most likely this gets overriden by some styles from MUIv4
        // try removing when MUIv4 is gone
        endAdornment: {
          top: "inherit",
        },
        // TODO: remove when this gets merged https://github.com/mui/material-ui/issues/28465
        root: {
          ".MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
            paddingRight: "39px",
            // or padding: theme.spacing(X) if you want to be more precise & already defined your theme
          },
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiCssBaseline: {
      // @ts-ignore
      styleOverrides: (themeParam) => `
        body {
          display: flex;
          -webkit-font-smoothing: antialiased;
        }
        table {
          border-collapse: collapse
        }
        
        a {
          color: ${themeParam.palette.primary.main};
          background-color: transparent;
          text-decoration: none;
          transition: color 0.15s ease, background-color 0.15s ease,
            border-color 0.15s ease, box-shadow 0.15s ease;
        
          &:hover {
            color: ${themeParam.palette.primary.light};
          }
          &:hover,
          &:focus,
          &:active {
            text-decoration: none;
          }
        }
        
        img {
          vertical-align: middle;
        }

        .input-feedback-text-placeholder {
          height: 19px;
        }
        
        .color-inherit {
          color: inherit;
        }
        
        .cursor-not-allowed {
          cursor: not-allowed !important;
        }
        
        .cursor-pointer {
          cursor: pointer;
        }

        // Hide number input arrows

        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
          appearance: textfield;
        }
      `,
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;
