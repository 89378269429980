import { isEmptyObject } from "../utils/isEmptyObject";
import type { ResponseMeta } from "./generated/v1/models";

export type APICall<T> = Promise<T>;

export type PaginationMeta = {
  CurrentPage: number;
  NextPageUrl: string;
  PageSize: number;
  PreviousPageUrl: string;
  TotalPages: number;
  TotalResults: number;
};

export type Paginated<T> = {
  Data: T;
  Meta: ResponseMeta;
};

export type QueryParamsSortType<T = Record<string, unknown>> = {
  field: keyof T;
  order: "asc" | "desc";
};

export type QueryParamsSingleFilterType<T = Record<string, unknown>> = {
  field: keyof T;
  operator: "eq" | "neq" | "gt" | "lt" | "gte" | "lte";
  value: boolean | string | null;
};

export type QueryParamsType<T = Record<string, unknown>> = {
  sort?: QueryParamsSortType<T>;
  // This technically can be a `string` as well, but it's pain in the ass to type it
  filter?: (QueryParamsSingleFilterType<T> | string)[] | null; // | string;
  page?: number;
  limit?: number;
  [key: string]: unknown;
};

export const parseParams = <T>(object: QueryParamsType<T>) => {
  const queryParams = new URLSearchParams();
  Object.entries(object).forEach(([key, value]) => {
    if (key === "sort") {
      const sortValue = value as QueryParamsType["sort"];
      if (sortValue) {
        const parsedSort = `${sortValue.field}::${sortValue.order}`;
        queryParams.append("sort", parsedSort);
      }
      return;
    }

    if (key === "filter") {
      const filterValue = value as QueryParamsType["filter"];

      if (typeof filterValue === "string") {
        queryParams.append("filter", filterValue);
        return;
      }

      if (filterValue?.length) {
        const parsedFilter = filterValue
          .map((stringOrObject) => {
            if (typeof stringOrObject === "string") {
              return stringOrObject;
            } else {
              return `${stringOrObject.field}::${stringOrObject.operator}::${stringOrObject.value}`;
            }
          })
          .join(",");
        queryParams.append("filter", parsedFilter);
      }
      return;
    }

    if (value) {
      const otherParamValue = value as string | number;
      queryParams.append(key, otherParamValue.toString());
      return;
    }
  });

  return queryParams;
};

export const normalizeQueryParams = <T>(object?: QueryParamsType<T>) => {
  if (!object) {
    return "";
  }
  if (isEmptyObject(object)) {
    return "";
  }
  return `?${new URLSearchParams(parseParams(object)).toString()}`;
};

export const stringifyFilterQueryParam = (
  filter: QueryParamsType["filter"],
) => {
  if (typeof filter === "string") {
    return filter;
  }

  return (
    filter
      ?.map((stringOrObject) => {
        if (typeof stringOrObject === "string") {
          return stringOrObject;
        } else {
          return `${stringOrObject.field}::${stringOrObject.operator}::${stringOrObject.value}`;
        }
      })
      .join(",") ?? ""
  );
};
