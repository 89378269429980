import { datadogLogs } from "@datadog/browser-logs";

export const initializeDatadogLogs = () => {
  const clientToken = import.meta.env.REACT_APP_DATADOG_CLIENT_TOKEN;

  if (import.meta.env.PROD) {
    if (!clientToken) {
      console.error(
        "Warning: Datadog clientToken is missing in environmental variables - Datadog Logs will not work properly",
      );
    } else {
      datadogLogs.init({
        clientToken,
        site: "datadoghq.eu",
        env: import.meta.env.MODE,
        forwardErrorsToLogs: false,
        sampleRate: 100,
      });
    }
  }
};
