import type { ReactNode } from "react";
import {
  Autocomplete,
  CircularProgress,
  TextField,
  type AutocompleteProps,
  type TextFieldProps,
} from "@mui/material";
import { useField, useFormikContext, type FormikHelpers } from "formik";

import type { MakeOptional } from "utils/types";

export type AutocompleteOptiontType<T = any> = { label: string; value: T };

type FormikAutocompleteProps<
  Option extends AutocompleteOptiontType,
  DisableClearable extends boolean | undefined = undefined,
> = {
  name: string;
  label: string | ReactNode;
  helperText?: TextFieldProps["helperText"];
  onChangeCallback?: (
    newValue: Option | null,
    formikHelpers: {
      setFieldValue: FormikHelpers<unknown>["setFieldValue"];
      values: unknown;
    },
  ) => void;
} & MakeOptional<
  AutocompleteProps<Option, false, DisableClearable, false>,
  "renderInput"
>;

/**
 * This Autocomplete does NOT accept:
 * - multiple choices
 * - free solo feature
 */
const FormikAutocomplete = <
  Option extends AutocompleteOptiontType,
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  options,
  label,
  loading,
  size,
  helperText,
  required,
  onChangeCallback,
  ...rest
}: FormikAutocompleteProps<Option, DisableClearable> &
  Pick<TextFieldProps, "required">) => {
  const [field, meta, helpers] = useField<string | null>(name);
  const { setFieldValue, values } = useFormikContext<unknown>();

  const autocompleteValue =
    options.find((option) => option.value === field.value) ?? null;

  return (
    <Autocomplete
      size={size}
      options={options}
      onChange={async (_e, value) => {
        helpers.setValue(value !== null ? value.value : null);
        onChangeCallback &&
          onChangeCallback(value, {
            setFieldValue: setFieldValue,
            values: values,
          });
      }}
      // @ts-expect-error - couldn't satisfy typescript, but 99.9% will work.
      value={autocompleteValue}
      onBlur={field.onBlur}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          name={field.name}
          required={required}
          label={label}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error ? meta.error : helperText}
          size={size}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      {...rest}
    />
  );
};

export default FormikAutocomplete;
