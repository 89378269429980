import React, { type FC } from "react";
import {
  Auth0Provider as Auth0ActualProvider,
  type Auth0ProviderOptions,
} from "@auth0/auth0-react";

const auth0options: Auth0ProviderOptions = {
  domain: import.meta.env.REACT_APP_AUTH0_DOMAIN || "variable_not_specified",
  clientId:
    import.meta.env.REACT_APP_AUTH0_CLIENT_ID || "variable_not_specified",
  authorizationParams: {
    redirect_uri: `${window.location.origin}`,
    audience: import.meta.env.REACT_APP_AUTH0_AUDIENCE,
  },
  useRefreshTokens: true,
  cacheLocation: "localstorage",
};

const Auth0Provider: FC = ({ children }) => {
  return (
    <Auth0ActualProvider {...auth0options}>{children}</Auth0ActualProvider>
  );
};

export default Auth0Provider;
