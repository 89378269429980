import type { AxiosResponse } from "axios";
import { format, getYear, isToday, isYesterday } from "date-fns";

import type { SelectOption } from "components/forms/mui/FormikSelect";
import { enCountriesWithCurrencyAndTimeZone } from "utils/countryList";

export function formatDateWithDayLabels(d: Date) {
  if (isToday(d)) return `Today`;
  if (isYesterday(d)) return `Yesterday`;

  return format(d, "dd/MM/yyyy");
}

export function round(num: number | undefined, numberOfDecimals = 2) {
  if (num) return Number(num).toFixed(numberOfDecimals);
  if (num === 0) return num;
  return "-";
}

export function downloadBlob(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any,
  filename: string,
  contentType: string,
) {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const el = document.createElement("a");
  el.href = url;
  el.setAttribute("download", filename);
  el.click();
}

export const status202RefetchInterval = <Response = unknown>(
  data: AxiosResponse<Response> | undefined,
) => {
  return data && data.status === 202 ? 5000 : false;
};

export const getCountryName = (countryCode: string) => {
  return (
    enCountriesWithCurrencyAndTimeZone.find((x) => x.value === countryCode)
      ?.label ?? ""
  );
};

export const formatPercentage = (value: number | undefined) => {
  if (value !== undefined) {
    const percentageValue = value * 100;
    const formattedPercentage = percentageValue.toFixed(0);

    return `${formattedPercentage}%`;
  }
  return "-";
};

export const reportMonthOptions: SelectOption[] = [
  { value: "all", label: "All year" },
  { value: "0", label: "January" },
  { value: "1", label: "February" },
  { value: "2", label: "March" },
  { value: "3", label: "April" },
  { value: "4", label: "May" },
  { value: "5", label: "June" },
  { value: "6", label: "July" },
  { value: "7", label: "August" },
  { value: "8", label: "September" },
  { value: "9", label: "October" },
  { value: "10", label: "November" },
  { value: "11", label: "December" },
];

export const getYearReportYearOptions = () => {
  const yearOptions: SelectOption[] = [];

  for (let year = 2019; year <= getYear(new Date()); year++) {
    yearOptions.push({
      value: year,
      label: year.toString(),
    });
  }

  return yearOptions;
};

export const getNoUtcIsoFormat = (date: Date) => {
  const localeTimeFormat = format(new Date(date), "PPpp");
  const parsedDate = new Date(localeTimeFormat);

  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(parsedDate.getDate()).padStart(2, "0");
  const hours = String(parsedDate.getHours()).padStart(2, "0");
  const minutes = String(parsedDate.getMinutes()).padStart(2, "0");
  const seconds = String(parsedDate.getSeconds()).padStart(2, "0");

  const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
  return isoString;
};

export function timingSafeEqual(a: any, b: any) {
  if (a.length !== b.length) return false;

  let result = 0;
  for (let i = 0; i < a.length; i++) {
    result |= a.charCodeAt(i) ^ b.charCodeAt(i);
  }

  return result === 0;
}
