import ReactDOM from "react-dom";

import { initializeDatadogLogs } from "utils/datadogInit";
import { initializeSentry } from "utils/sentry";

import App from "./App";

initializeDatadogLogs();
void initializeSentry();

// https://vitejs.dev/guide/build.html#load-error-handling
// Addressing error when new deployment happened:
// Failed to load module script: Expected a JavaScript module script but the server responded with a MIME type of "text/html". Strict MIME type checking is enforced for module scripts per HTML spec.
window.addEventListener("vite:preloadError", (event) => {
  console.error("vite:preloadError", event);
});

ReactDOM.render(<App />, document.getElementById("root"));
