/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Shore Power Hub API
 * Shore Power Hub provides the backend services for Plug Insight. <br><br>The spec is available at \<this URL\>/swagger.json.
 * OpenAPI spec version: 2
 */
import {
  useQuery,
  type QueryFunction,
  type QueryKey,
  type UseQueryOptions,
  type UseQueryResult,
} from "@tanstack/react-query";

import { useOrvalAxiosInstance, type ErrorType } from "../../useOrvalAxios";
import type {
  ApitypeAppError2,
  ApitypePermissionsResponse,
  ApitypeRolesResponse,
  GetPermissionsParams,
  GetRolesParams,
} from "./models";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Get Permissions
 */
export const useGetPermissionsHook = () => {
  const getPermissions = useOrvalAxiosInstance<ApitypePermissionsResponse>();

  return (
    params: GetPermissionsParams,
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
    signal?: AbortSignal,
  ) => {
    return getPermissions(
      {
        url: `/api/v2/system/authorization/permissions`,
        method: "GET",
        params,
        signal,
      },
      options,
    );
  };
};

export const getGetPermissionsQueryKey = (params: GetPermissionsParams) => {
  return [
    `/api/v2/system/authorization/permissions`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGetPermissionsQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPermissionsHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(
  params: GetPermissionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetPermissionsHook>>>,
      TError,
      TData
    >;
    request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPermissionsQueryKey(params);

  const getPermissions = useGetPermissionsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetPermissionsHook>>>
  > = ({ signal }) => getPermissions(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetPermissionsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPermissionsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetPermissionsHook>>>
>;
export type GetPermissionsQueryError = ErrorType<ApitypeAppError2>;

/**
 * @summary Get Permissions
 */
export const useGetPermissions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetPermissionsHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(
  params: GetPermissionsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetPermissionsHook>>>,
      TError,
      TData
    >;
    request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetPermissionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Get Roles
 */
export const useGetRolesHook = () => {
  const getRoles = useOrvalAxiosInstance<ApitypeRolesResponse>();

  return (
    params: GetRolesParams,
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
    signal?: AbortSignal,
  ) => {
    return getRoles(
      {
        url: `/api/v2/system/authorization/roles`,
        method: "GET",
        params,
        signal,
      },
      options,
    );
  };
};

export const getGetRolesQueryKey = (params: GetRolesParams) => {
  return [
    `/api/v2/system/authorization/roles`,
    ...(params ? [params] : []),
  ] as const;
};

export const useGetRolesQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetRolesHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(
  params: GetRolesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetRolesHook>>>,
      TError,
      TData
    >;
    request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRolesQueryKey(params);

  const getRoles = useGetRolesHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGetRolesHook>>>
  > = ({ signal }) => getRoles(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGetRolesHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetRolesQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGetRolesHook>>>
>;
export type GetRolesQueryError = ErrorType<ApitypeAppError2>;

/**
 * @summary Get Roles
 */
export const useGetRoles = <
  TData = Awaited<ReturnType<ReturnType<typeof useGetRolesHook>>>,
  TError = ErrorType<ApitypeAppError2>,
>(
  params: GetRolesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useGetRolesHook>>>,
      TError,
      TData
    >;
    request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGetRolesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
