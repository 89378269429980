/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Shore Power Hub API
 * Shore Power Hub provides the backend services for Plug Insight. <br><br>The spec is available at \<this URL\>/swagger.json.
 * OpenAPI spec version: 2
 */
import {
  useMutation,
  type MutationFunction,
  type UseMutationOptions,
} from "@tanstack/react-query";

import {
  useOrvalAxiosInstance,
  type BodyType,
  type ErrorType,
} from "../../useOrvalAxios";
import type { ApitypeAppError2, HandlersSupportRequest } from "./models";

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Send a support request - WIP
 */
export const useSendasupportrequestWIPHook = () => {
  const sendasupportrequestWIP = useOrvalAxiosInstance<void>();

  return (
    handlersSupportRequest: BodyType<HandlersSupportRequest>,
    options?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>,
  ) => {
    return sendasupportrequestWIP(
      {
        url: `/api/v2/support`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data: handlersSupportRequest,
      },
      options,
    );
  };
};

export const useSendasupportrequestWIPMutationOptions = <
  TError = ErrorType<ApitypeAppError2>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSendasupportrequestWIPHook>>>,
    TError,
    { data: BodyType<HandlersSupportRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useSendasupportrequestWIPHook>>>,
  TError,
  { data: BodyType<HandlersSupportRequest> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const sendasupportrequestWIP = useSendasupportrequestWIPHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useSendasupportrequestWIPHook>>>,
    { data: BodyType<HandlersSupportRequest> }
  > = (props) => {
    const { data } = props ?? {};

    return sendasupportrequestWIP(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendasupportrequestWIPMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSendasupportrequestWIPHook>>>
>;
export type SendasupportrequestWIPMutationBody =
  BodyType<HandlersSupportRequest>;
export type SendasupportrequestWIPMutationError = ErrorType<ApitypeAppError2>;

/**
 * @summary Send a support request - WIP
 */
export const useSendasupportrequestWIP = <
  TError = ErrorType<ApitypeAppError2>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useSendasupportrequestWIPHook>>>,
    TError,
    { data: BodyType<HandlersSupportRequest> },
    TContext
  >;
  request?: SecondParameter<ReturnType<typeof useOrvalAxiosInstance>>;
}) => {
  const mutationOptions = useSendasupportrequestWIPMutationOptions(options);

  return useMutation(mutationOptions);
};
