import { create } from "zustand";
import { persist } from "zustand/middleware";

type CookiesType = {
  necessary: true;
};

type CookiesConsentStoreType = {
  consent: Map<string, CookiesType>;
  setConsent: (userId: string, newConsent: CookiesType) => void;
};

const useCookieConsentStore = create<CookiesConsentStoreType>()(
  persist(
    (set) => ({
      consent: new Map(),
      setConsent: (userId: string, newConsent: CookiesType) =>
        set((prev) => ({
          consent: new Map(prev.consent).set(userId, newConsent),
        })),
    }),
    {
      name: `cookie-consent-store`,
      version: 2,
      // default serialization doesn't work with Map
      serialize: (data) => {
        return JSON.stringify({
          ...data,
          state: {
            ...data.state,
            consent: Array.from(data.state.consent.entries()),
          },
        });
      },
      deserialize: (value) => {
        const data = JSON.parse(value);

        data.state.consent = new Map(data.state.consent);

        return data;
      },
    },
  ),
);

export {
  useCookieConsentStore,
  type CookiesType,
  type CookiesConsentStoreType,
};
