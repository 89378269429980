import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Form, FormikProvider, useFormik } from "formik";
import { bindDialog, type PopupState } from "material-ui-popup-state/hooks";
import { useSnackbar } from "notistack";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import { useSendasupportrequestWIP } from "api/generated/v2/support";
import FormikAutocomplete from "components/forms/mui/FormikAutocomplete";
import FormikCheckbox from "components/forms/mui/FormikCheckbox";
import FormikTextField from "components/forms/mui/FormikTextField";
import { SnackbarActionIcon } from "components/providers/ReactQueryProvider";
import { useUserStore } from "components/stores/UserStore";

const topicsOptions = [
  { value: "general", label: "General" },
  { value: "technical", label: "Technical" },
  { value: "invoicing", label: "Invoicing" },
];

const schema = yup.object().shape({
  email: yup.string().email(),
  topic: yup.string().required(),
  description: yup.string().required().max(1000),
  consentToFurtherContact: yup.boolean().required(),
});

type FormValues = yup.InferType<typeof schema>;

function BugReportDialog({ ...popupState }: PopupState) {
  const { user } = useUserStore();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { mutateAsync: sendFeedback } = useSendasupportrequestWIP({
    mutation: {
      onSuccess: () => {
        enqueueSnackbar(
          <FormattedMessage defaultMessage="An email confirmation is on its way to you shortly. If you don't see it in your inbox, please take a moment to check your spam folder, just in case." />,
          {
            variant: "success",
            action: (key) => (
              <SnackbarActionIcon action={() => closeSnackbar(key)} />
            ),
            key: "feddback-bug-success",
            autoHideDuration: 10000,
          },
        );
      },
    },
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      email: user?.Email || "",
      description: "",
      topic: "",
      consentToFurtherContact: false,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      await sendFeedback({
        data: {
          MayContactMe: values.consentToFurtherContact ? "yes" : "no",
          Text: values.description,
          Topic: values.topic,
          Type: "bug",
          Url: btoa(window.location.href),
        },
      });
      formik.resetForm();
      popupState.close();
    },
    validationSchema: schema,
  });

  return (
    <Dialog {...bindDialog(popupState)}>
      <FormikProvider value={formik}>
        <Form noValidate>
          <DialogTitle>
            <FormattedMessage defaultMessage="Report a bug" />
          </DialogTitle>
          <DialogContent>
            <p>
              <FormattedMessage defaultMessage="Encounter an <b>issue</b> or a <b>bug</b>? Please describe the issue below, and our team will take a look at it." />
            </p>
            <p>
              <FormattedMessage defaultMessage="We value your contributions in enhancing our services. Thank you for being a part of our journey!" />
            </p>
            <FormikTextField
              name="email"
              label={<FormattedMessage defaultMessage="Email" />}
            />
            <FormikAutocomplete
              name="topic"
              label={
                <FormattedMessage defaultMessage="What do you need help with?" />
              }
              options={topicsOptions}
            />
            <FormikTextField
              name="description"
              label={
                <FormattedMessage defaultMessage="Describe your question" />
              }
              multiline
              minRows={4}
              helperText={
                <FormattedMessage
                  defaultMessage={"{charactersLeft}/1000 characters remaining"}
                  values={{
                    charactersLeft: 1000 - formik.values.description.length,
                  }}
                />
              }
            />
            <FormikCheckbox
              name="consentToFurtherContact"
              label={
                <FormattedMessage defaultMessage="Plug AS may contact me for further details." />
              }
            />
            <Typography variant="body2">
              <FormattedMessage defaultMessage="By submitting this form, you agree that your email address and the URL of the page are attached to the ticket." />
            </Typography>
          </DialogContent>

          <DialogActions>
            <Button type="button" onClick={popupState.close}>
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Submit request
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}

export default BugReportDialog;
