import {
  createContext,
  useContext,
  useState,
  type Dispatch,
  type SetStateAction,
} from "react";

const CookieConsentContext = createContext<
  | {
      isCookieConsentModalOpen: boolean;
      setIsCookieConsentModal: Dispatch<SetStateAction<boolean>>;
    }
  | undefined
>(undefined);

type CookieConsentProviderProps = { children: React.ReactNode };

const CookieConsentProvider = ({ children }: CookieConsentProviderProps) => {
  const [isCookieConsentModalOpen, setIsCookieConsentModal] = useState(false);

  return (
    <CookieConsentContext.Provider
      value={{ isCookieConsentModalOpen, setIsCookieConsentModal }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
};

function useCookieConsentContext() {
  const context = useContext(CookieConsentContext);
  if (context === undefined) {
    throw new Error(
      "useCookieConsentContext must be used within a CookieConsentProvider",
    );
  }
  return context;
}

export { CookieConsentProvider, useCookieConsentContext };
