import type { FC } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import enLocale from "date-fns/locale/en-GB";
import nbLocale from "date-fns/locale/nb";

import type { WithChildren } from "utils/types";

import { LanguageEnum, useLang } from "../I18nContextProvider";
import theme from "./theme";

const MUIProvider = ({ children }: WithChildren) => {
  const locale = useLang();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={locale === LanguageEnum.NO ? nbLocale : enLocale}
      >
        {children}
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export const MuiThemeProvider: FC = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default MUIProvider;
