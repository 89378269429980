import { useAuth0 } from "@auth0/auth0-react";
import Axios, {
  AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
} from "axios";
import qs from "qs";

import { useUserStore } from "components/stores/UserStore";

import { parseParams } from "./utils";

export const axiosInstanceOrval = Axios.create({
  baseURL: `https://${import.meta.env.REACT_APP_API_URL}`,
});

type RequestOptions = { returnAxiosResponse?: boolean } & AxiosRequestConfig;

export const useOrvalAxiosInstance = <
  TResponse = unknown,
  TBodyData = unknown,
>(): (<TOptions extends RequestOptions | undefined = undefined>(
  config: AxiosRequestConfig<TBodyData>,
  options?: TOptions,
) => TOptions extends { returnAxiosResponse: true }
  ? Promise<AxiosResponse<TResponse>>
  : Promise<TResponse>) => {
  const { getAccessTokenSilently, logout } = useAuth0();

  const setToken = useUserStore((s) => s.setToken);

  return async (config, options) => {
    try {
      const accessToken = await getAccessTokenSilently();
      setToken(accessToken || null);

      const response = await axiosInstanceOrval<TResponse>({
        ...config,
        ...options,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          ...options?.headers,
        },
        paramsSerializer: (params) => {
          // generated queries are not using normalizeQueryParams, so we need to parse `filter` and `sort` here
          const parsedParams = {
            ...params,
            filter:
              params.filter && parseParams(params).get("filter")?.toString(),
            sort: params.sort && parseParams(params).get("sort")?.toString(),
          };
          return qs.stringify(parsedParams, { arrayFormat: "comma" });
        },
      });

      if (options?.returnAxiosResponse) {
        return response as any;
      }

      return response.data;
    } catch (err) {
      // should catch all errors thrown from axiosInstanceOrval
      if (err instanceof AxiosError) {
        return Promise.reject(err.response ?? err.message);
      } else {
        // likely error thrown from getAccessTokenSilently()
        console.error(err, config);
        if (window.location.pathname !== "/login") {
          void logout({
            logoutParams: { returnTo: `${window.location.origin}/login` },
          });
        }
      }
    }
  };
};

export default useOrvalAxiosInstance;

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;

// In case you want to wrap the body type (optional)
// (if the custom instance is processing data before sending it, like changing the case for example)
export type BodyType<BodyData> = BodyData;
