import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Stack, Tab, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { useHash } from "utils/hooks/useHash";

import Permissions from "./Permissions";
import Roles from "./Roles";

const tabs = ["", "#permissions"] as const;
type RolesAndPermissionsType = (typeof tabs)[number];

const AuthorisationDetails = () => {
  const { formatMessage } = useIntl();

  const [hash, setHash] = useHash(tabs);

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: RolesAndPermissionsType,
  ) => {
    setHash(newValue);
  };

  return (
    <>
      <Stack>
        <Typography variant="h1" mb={2}>
          <FormattedMessage defaultMessage="Roles and permissions" />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="View the different user roles and permissions in the system" />
        </Typography>
      </Stack>
      <TabContext value={hash}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
          <TabList
            onChange={handleChange}
            variant="scrollable"
            orientation="horizontal"
          >
            <Tab
              label={formatMessage({
                defaultMessage: "Roles",
              })}
              sx={{
                "&.Mui-selected": {
                  color: "text.hint",
                },
              }}
              value=""
            />
            <Tab
              label={formatMessage({
                defaultMessage: "Permissions",
              })}
              sx={{
                "&.Mui-selected": {
                  color: "text.hint",
                },
              }}
              value="#permissions"
            />
          </TabList>
        </Box>
        <TabPanel sx={{ p: 0 }} value="">
          <Roles />
        </TabPanel>
        <TabPanel sx={{ p: 0 }} value="#permissions">
          <Permissions />
        </TabPanel>
      </TabContext>
    </>
  );
};

export default AuthorisationDetails;
