// data from: https://github.com/umpirsky/country-list/tree/master/data

export const enCountryList = [
  { value: "AF", label: "Afghanistan" },
  { value: "AX", label: "\u00c5land Islands" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AS", label: "American Samoa" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AQ", label: "Antarctica" },
  { value: "AG", label: "Antigua & Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BE", label: "Belgium" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia" },
  { value: "BA", label: "Bosnia & Herzegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BV", label: "Bouvet Island" },
  { value: "BR", label: "Brazil" },
  { value: "IO", label: "British Indian Ocean Territory" },
  { value: "VG", label: "British Virgin Islands" },
  { value: "BN", label: "Brunei" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "KH", label: "Cambodia" },
  { value: "CM", label: "Cameroon" },
  { value: "CA", label: "Canada" },
  { value: "CV", label: "Cape Verde" },
  { value: "BQ", label: "Caribbean Netherlands" },
  { value: "KY", label: "Cayman Islands" },
  { value: "CF", label: "Central African Republic" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CX", label: "Christmas Island" },
  { value: "CC", label: "Cocos (Keeling) Islands" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoros" },
  { value: "CG", label: "Congo - Brazzaville" },
  { value: "CD", label: "Congo - Kinshasa" },
  { value: "CK", label: "Cook Islands" },
  { value: "CR", label: "Costa Rica" },
  { value: "CI", label: "C\u00f4te d\u2019Ivoire" },
  { value: "HR", label: "Croatia" },
  { value: "CU", label: "Cuba" },
  { value: "CW", label: "Cura\u00e7ao" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czechia" },
  { value: "DK", label: "Denmark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominican Republic" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Equatorial Guinea" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "SZ", label: "Eswatini" },
  { value: "ET", label: "Ethiopia" },
  { value: "FK", label: "Falkland Islands" },
  { value: "FO", label: "Faroe Islands" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "GF", label: "French Guiana" },
  { value: "PF", label: "French Polynesia" },
  { value: "TF", label: "French Southern Territories" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Greece" },
  { value: "GL", label: "Greenland" },
  { value: "GD", label: "Grenada" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala" },
  { value: "GG", label: "Guernsey" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "HM", label: "Heard & McDonald Islands" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hong Kong SAR China" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IR", label: "Iran" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Ireland" },
  { value: "IM", label: "Isle of Man" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italy" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JE", label: "Jersey" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KI", label: "Kiribati" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LA", label: "Laos" },
  { value: "LV", label: "Latvia" },
  { value: "LB", label: "Lebanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libya" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MO", label: "Macao SAR China" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Micronesia" },
  { value: "MD", label: "Moldova" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "MS", label: "Montserrat" },
  { value: "MA", label: "Morocco" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar (Burma)" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Netherlands" },
  { value: "NC", label: "New Caledonia" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "NF", label: "Norfolk Island" },
  { value: "KP", label: "North Korea" },
  { value: "MK", label: "North Macedonia" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "NO", label: "Norway" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PS", label: "Palestinian Territories" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippines" },
  { value: "PN", label: "Pitcairn Islands" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "PR", label: "Puerto Rico" },
  { value: "QA", label: "Qatar" },
  { value: "RE", label: "R\u00e9union" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russia" },
  { value: "RW", label: "Rwanda" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "S\u00e3o Tom\u00e9 & Pr\u00edncipe" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbia" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SX", label: "Sint Maarten" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "South Africa" },
  { value: "GS", label: "South Georgia & South Sandwich Islands" },
  { value: "KR", label: "South Korea" },
  { value: "SS", label: "South Sudan" },
  { value: "ES", label: "Spain" },
  { value: "LK", label: "Sri Lanka" },
  { value: "BL", label: "St. Barth\u00e9lemy" },
  { value: "SH", label: "St. Helena" },
  { value: "KN", label: "St. Kitts & Nevis" },
  { value: "LC", label: "St. Lucia" },
  { value: "MF", label: "St. Martin" },
  { value: "PM", label: "St. Pierre & Miquelon" },
  { value: "VC", label: "St. Vincent & Grenadines" },
  { value: "SD", label: "Sudan" },
  { value: "SR", label: "Suriname" },
  { value: "SJ", label: "Svalbard & Jan Mayen" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "SY", label: "Syria" },
  { value: "TW", label: "Taiwan" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TZ", label: "Tanzania" },
  { value: "TH", label: "Thailand" },
  { value: "TL", label: "Timor-Leste" },
  { value: "TG", label: "Togo" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad & Tobago" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Turkey" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TC", label: "Turks & Caicos Islands" },
  { value: "TV", label: "Tuvalu" },
  { value: "UM", label: "U.S. Outlying Islands" },
  { value: "VI", label: "U.S. Virgin Islands" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "GB", label: "United Kingdom" },
  { value: "US", label: "United States" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VA", label: "Vatican City" },
  { value: "VE", label: "Venezuela" },
  { value: "VN", label: "Vietnam" },
  { value: "WF", label: "Wallis & Futuna" },
  { value: "EH", label: "Western Sahara" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" },
];

export const nbCountryList = [
  { value: "AF", label: "Afghanistan" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algerie" },
  { value: "AS", label: "Amerikansk Samoa" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AQ", label: "Antarktis" },
  { value: "AG", label: "Antigua og Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AW", label: "Aruba" },
  { value: "AZ", label: "Aserbajdsjan" },
  { value: "AU", label: "Australia" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BE", label: "Belgia" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia" },
  { value: "BA", label: "Bosnia-Hercegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BV", label: "Bouvet\u00f8ya" },
  { value: "BR", label: "Brasil" },
  { value: "BN", label: "Brunei" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "CA", label: "Canada" },
  { value: "KY", label: "Cayman\u00f8yene" },
  { value: "CL", label: "Chile" },
  { value: "CX", label: "Christmas\u00f8ya" },
  { value: "CO", label: "Colombia" },
  { value: "CK", label: "Cook\u00f8yene" },
  { value: "CR", label: "Costa Rica" },
  { value: "CU", label: "Cuba" },
  { value: "CW", label: "Cura\u00e7ao" },
  { value: "DK", label: "Danmark" },
  { value: "VI", label: "De amerikanske jomfru\u00f8yene" },
  { value: "VG", label: "De britiske jomfru\u00f8yene" },
  { value: "AE", label: "De forente arabiske emirater" },
  { value: "TF", label: "De franske s\u00f8rterritorier" },
  { value: "DO", label: "Den dominikanske republikk" },
  { value: "CF", label: "Den sentralafrikanske republikk" },
  { value: "IO", label: "Det britiske territoriet i Indiahavet" },
  { value: "PS", label: "Det palestinske omr\u00e5det" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "GQ", label: "Ekvatorial-Guinea" },
  { value: "SV", label: "El Salvador" },
  { value: "CI", label: "Elfenbenskysten" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estland" },
  { value: "SZ", label: "Eswatini" },
  { value: "ET", label: "Etiopia" },
  { value: "FK", label: "Falklands\u00f8yene" },
  { value: "FJ", label: "Fiji" },
  { value: "PH", label: "Filippinene" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "Frankrike" },
  { value: "GF", label: "Fransk Guyana" },
  { value: "PF", label: "Fransk Polynesia" },
  { value: "FO", label: "F\u00e6r\u00f8yene" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GD", label: "Grenada" },
  { value: "GL", label: "Gr\u00f8nland" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala" },
  { value: "GG", label: "Guernsey" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "HM", label: "Heard- og McDonald\u00f8yene" },
  { value: "GR", label: "Hellas" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hongkong S.A.R. Kina" },
  { value: "BY", label: "Hviterussland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IQ", label: "Irak" },
  { value: "IR", label: "Iran" },
  { value: "IE", label: "Irland" },
  { value: "IS", label: "Island" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italia" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "YE", label: "Jemen" },
  { value: "JE", label: "Jersey" },
  { value: "JO", label: "Jordan" },
  { value: "KH", label: "Kambodsja" },
  { value: "CM", label: "Kamerun" },
  { value: "CV", label: "Kapp Verde" },
  { value: "BQ", label: "Karibisk Nederland" },
  { value: "KZ", label: "Kasakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "CN", label: "Kina" },
  { value: "KG", label: "Kirgisistan" },
  { value: "KI", label: "Kiribati" },
  { value: "CC", label: "Kokos\u00f8yene" },
  { value: "KM", label: "Komorene" },
  { value: "CG", label: "Kongo-Brazzaville" },
  { value: "CD", label: "Kongo-Kinshasa" },
  { value: "HR", label: "Kroatia" },
  { value: "KW", label: "Kuwait" },
  { value: "CY", label: "Kypros" },
  { value: "LA", label: "Laos" },
  { value: "LV", label: "Latvia" },
  { value: "LS", label: "Lesotho" },
  { value: "LB", label: "Libanon" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libya" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Litauen" },
  { value: "LU", label: "Luxemburg" },
  { value: "MO", label: "Macao S.A.R. Kina" },
  { value: "MG", label: "Madagaskar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldivene" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "IM", label: "Man" },
  { value: "MA", label: "Marokko" },
  { value: "MH", label: "Marshall\u00f8yene" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Mikronesiaf\u00f8derasjonen" },
  { value: "MD", label: "Moldova" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "MS", label: "Montserrat" },
  { value: "MZ", label: "Mosambik" },
  { value: "MM", label: "Myanmar (Burma)" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NL", label: "Nederland" },
  { value: "NP", label: "Nepal" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "KP", label: "Nord-Korea" },
  { value: "MK", label: "Nord-Makedonia" },
  { value: "MP", label: "Nord-Marianene" },
  { value: "NF", label: "Norfolk\u00f8ya" },
  { value: "NO", label: "Norge" },
  { value: "NC", label: "Ny-Caledonia" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua Ny-Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PN", label: "Pitcairn\u00f8yene" },
  { value: "PL", label: "Polen" },
  { value: "PT", label: "Portugal" },
  { value: "PR", label: "Puerto Rico" },
  { value: "QA", label: "Qatar" },
  { value: "RE", label: "R\u00e9union" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russland" },
  { value: "RW", label: "Rwanda" },
  { value: "KN", label: "Saint Kitts og Nevis" },
  { value: "BL", label: "Saint-Barth\u00e9lemy" },
  { value: "MF", label: "Saint-Martin" },
  { value: "PM", label: "Saint-Pierre-et-Miquelon" },
  { value: "SB", label: "Salomon\u00f8yene" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "S\u00e3o Tom\u00e9 og Pr\u00edncipe" },
  { value: "SA", label: "Saudi-Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbia" },
  { value: "SC", label: "Seychellene" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SX", label: "Sint Maarten" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SO", label: "Somalia" },
  { value: "ES", label: "Spania" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SH", label: "St. Helena" },
  { value: "LC", label: "St. Lucia" },
  { value: "VC", label: "St. Vincent og Grenadinene" },
  { value: "GB", label: "Storbritannia" },
  { value: "SD", label: "Sudan" },
  { value: "SR", label: "Surinam" },
  { value: "SJ", label: "Svalbard og Jan Mayen" },
  { value: "CH", label: "Sveits" },
  { value: "SE", label: "Sverige" },
  { value: "SY", label: "Syria" },
  { value: "ZA", label: "S\u00f8r-Afrika" },
  { value: "GS", label: "S\u00f8r-Georgia og S\u00f8r-Sandwich\u00f8yene" },
  { value: "KR", label: "S\u00f8r-Korea" },
  { value: "SS", label: "S\u00f8r-Sudan" },
  { value: "TJ", label: "Tadsjikistan" },
  { value: "TW", label: "Taiwan" },
  { value: "TZ", label: "Tanzania" },
  { value: "TH", label: "Thailand" },
  { value: "TG", label: "Togo" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad og Tobago" },
  { value: "TD", label: "Tsjad" },
  { value: "CZ", label: "Tsjekkia" },
  { value: "TN", label: "Tunisia" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TC", label: "Turks- og Caicos\u00f8yene" },
  { value: "TV", label: "Tuvalu" },
  { value: "TR", label: "Tyrkia" },
  { value: "DE", label: "Tyskland" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraina" },
  { value: "HU", label: "Ungarn" },
  { value: "UY", label: "Uruguay" },
  { value: "US", label: "USA" },
  { value: "UM", label: "USAs ytre \u00f8yer" },
  { value: "UZ", label: "Usbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VA", label: "Vatikanstaten" },
  { value: "VE", label: "Venezuela" },
  { value: "EH", label: "Vest-Sahara" },
  { value: "VN", label: "Vietnam" },
  { value: "WF", label: "Wallis og Futuna" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" },
  { value: "TL", label: "\u00d8st-Timor" },
  { value: "AT", label: "\u00d8sterrike" },
  { value: "AX", label: "\u00c5land" },
];

export type IEnCountriesWithCurrencyAndTimeZone = {
  value: string;
  label: string;
  timezone: string | null;
  currency: string | null;
};

export const enCountriesWithCurrencyAndTimeZone: IEnCountriesWithCurrencyAndTimeZone[] =
  [
    {
      value: "AF",
      label: "Afghanistan",
      timezone: "Asia/Kabul",
      currency: "AFN",
    },
    {
      value: "GB-SCT",
      label: "Scotland",
      timezone: "Europe/London",
      currency: "GBP",
    },
    {
      value: "AX",
      label: "\u00c5land Islands",
      timezone: "Europe/Mariehamn",
      currency: "EUR",
    },
    {
      value: "AL",
      label: "Albania",
      timezone: "Europe/Tirane",
      currency: "ALL",
    },
    {
      value: "DZ",
      label: "Algeria",
      timezone: "Africa/Algiers",
      currency: "DZD",
    },
    {
      value: "AS",
      label: "American Samoa",
      timezone: "Pacific/Pago_Pago",
      currency: "USD",
    },
    {
      value: "AD",
      label: "Andorra",
      timezone: "Europe/Andorra",
      currency: "EUR",
    },
    {
      value: "AO",
      label: "Angola",
      timezone: "Africa/Luanda",
      currency: "AOA",
    },
    {
      value: "AI",
      label: "Anguilla",
      timezone: "America/Anguilla",
      currency: "XCD",
    },
    {
      value: "AQ",
      label: "Antarctica",
      timezone: "Antarctica/Palmer",
      currency: null,
    },
    {
      value: "AG",
      label: "Antigua & Barbuda",
      timezone: "America/Antigua",
      currency: "XCD",
    },
    {
      value: "AR",
      label: "Argentina",
      timezone: "America/Argentina/Buenos_Aires",
      currency: "ARS",
    },
    {
      value: "AM",
      label: "Armenia",
      timezone: "Asia/Yerevan",
      currency: "AMD",
    },
    { value: "AW", label: "Aruba", timezone: "America/Aruba", currency: "AWG" },
    {
      value: "AU",
      label: "Australia",
      timezone: "Australia/Sydney",
      currency: "AUD",
    },
    {
      value: "AT",
      label: "Austria",
      timezone: "Europe/Vienna",
      currency: "EUR",
    },
    {
      value: "AZ",
      label: "Azerbaijan",
      timezone: "Asia/Baku",
      currency: "AZN",
    },
    {
      value: "BS",
      label: "Bahamas",
      timezone: "America/Nassau",
      currency: "BSD",
    },
    {
      value: "BH",
      label: "Bahrain",
      timezone: "Asia/Bahrain",
      currency: "BHD",
    },
    {
      value: "BD",
      label: "Bangladesh",
      timezone: "Asia/Dhaka",
      currency: "BDT",
    },
    {
      value: "BB",
      label: "Barbados",
      timezone: "America/Barbados",
      currency: "BBD",
    },
    {
      value: "BY",
      label: "Belarus",
      timezone: "Europe/Minsk",
      currency: "BYN",
    },
    {
      value: "BE",
      label: "Belgium",
      timezone: "Europe/Brussels",
      currency: "EUR",
    },
    {
      value: "BZ",
      label: "Belize",
      timezone: "America/Belize",
      currency: "BZD",
    },
    {
      value: "BJ",
      label: "Benin",
      timezone: "Africa/Porto-Novo",
      currency: "XOF",
    },
    {
      value: "BM",
      label: "Bermuda",
      timezone: "Atlantic/Bermuda",
      currency: "BMD",
    },
    { value: "BT", label: "Bhutan", timezone: "Asia/Thimphu", currency: "BTN" },
    {
      value: "BO",
      label: "Bolivia",
      timezone: "America/La_Paz",
      currency: "BOB",
    },
    {
      value: "BA",
      label: "Bosnia & Herzegovina",
      timezone: "Europe/Sarajevo",
      currency: "BAM",
    },
    {
      value: "BW",
      label: "Botswana",
      timezone: "Africa/Gaborone",
      currency: "BWP",
    },
    { value: "BV", label: "Bouvet Island", timezone: null, currency: null },
    {
      value: "BR",
      label: "Brazil",
      timezone: "America/Sao_Paulo",
      currency: "BRL",
    },
    {
      value: "IO",
      label: "British Indian Ocean Territory",
      timezone: "Indian/Chagos",
      currency: "USD",
    },
    {
      value: "VG",
      label: "British Virgin Islands",
      timezone: "America/Tortola",
      currency: "USD",
    },
    { value: "BN", label: "Brunei", timezone: "Asia/Brunei", currency: "BND" },
    {
      value: "BG",
      label: "Bulgaria",
      timezone: "Europe/Sofia",
      currency: "BGN",
    },
    {
      value: "BF",
      label: "Burkina Faso",
      timezone: "Africa/Ouagadougou",
      currency: "XOF",
    },
    {
      value: "BI",
      label: "Burundi",
      timezone: "Africa/Bujumbura",
      currency: "BIF",
    },
    {
      value: "KH",
      label: "Cambodia",
      timezone: "Asia/Phnom_Penh",
      currency: "KHR",
    },
    {
      value: "CM",
      label: "Cameroon",
      timezone: "Africa/Douala",
      currency: "XAF",
    },
    {
      value: "CA",
      label: "Canada",
      timezone: "America/Toronto",
      currency: "CAD",
    },
    {
      value: "CV",
      label: "Cape Verde",
      timezone: "Atlantic/Cape_Verde",
      currency: "CVE",
    },
    {
      value: "BQ",
      label: "Caribbean Netherlands",
      timezone: "America/Kralendijk",
      currency: "USD",
    },
    {
      value: "KY",
      label: "Cayman Islands",
      timezone: "America/Cayman",
      currency: "KYD",
    },
    {
      value: "CF",
      label: "Central African Republic",
      timezone: "Africa/Bangui",
      currency: "XAF",
    },
    {
      value: "TD",
      label: "Chad",
      timezone: "Africa/Ndjamena",
      currency: "XAF",
    },
    {
      value: "CL",
      label: "Chile",
      timezone: "America/Santiago",
      currency: "CLP",
    },
    { value: "CN", label: "China", timezone: "Asia/Shanghai", currency: "CNY" },
    {
      value: "CX",
      label: "Christmas Island",
      timezone: "Indian/Christmas",
      currency: "AUD",
    },
    {
      value: "CC",
      label: "Cocos (Keeling) Islands",
      timezone: "Indian/Cocos",
      currency: "AUD",
    },
    {
      value: "CO",
      label: "Colombia",
      timezone: "America/Bogota",
      currency: "COP",
    },
    {
      value: "KM",
      label: "Comoros",
      timezone: "Indian/Comoro",
      currency: "KMF",
    },
    {
      value: "CG",
      label: "Congo - Brazzaville",
      timezone: "Africa/Brazzaville",
      currency: "XAF",
    },
    {
      value: "CD",
      label: "Congo - Kinshasa",
      timezone: "Africa/Kinshasa",
      currency: "CDF",
    },
    {
      value: "CK",
      label: "Cook Islands",
      timezone: "Pacific/Rarotonga",
      currency: "NZD",
    },
    {
      value: "CR",
      label: "Costa Rica",
      timezone: "America/Costa_Rica",
      currency: "CRC",
    },
    {
      value: "CI",
      label: "Côte d’Ivoire",
      timezone: "Africa/Abidjan",
      currency: "XOF",
    },
    {
      value: "HR",
      label: "Croatia",
      timezone: "Europe/Zagreb",
      currency: "HRK",
    },
    { value: "CU", label: "Cuba", timezone: "America/Havana", currency: "CUP" },
    {
      value: "CW",
      label: "Curaçao",
      timezone: "America/Curacao",
      currency: "ANG",
    },
    { value: "CY", label: "Cyprus", timezone: "Asia/Nicosia", currency: "EUR" },
    {
      value: "CZ",
      label: "Czechia",
      timezone: "Europe/Prague",
      currency: "CZK",
    },
    {
      value: "DK",
      label: "Denmark",
      timezone: "Europe/Copenhagen",
      currency: "DKK",
    },
    {
      value: "DJ",
      label: "Djibouti",
      timezone: "Africa/Djibouti",
      currency: "DJF",
    },
    {
      value: "DM",
      label: "Dominica",
      timezone: "America/Dominica",
      currency: "XCD",
    },
    {
      value: "DO",
      label: "Dominican Republic",
      timezone: "America/Santo_Domingo",
      currency: "DOP",
    },
    {
      value: "EC",
      label: "Ecuador",
      timezone: "America/Guayaquil",
      currency: "USD",
    },
    { value: "EG", label: "Egypt", timezone: "Africa/Cairo", currency: "EGP" },
    {
      value: "SV",
      label: "El Salvador",
      timezone: "America/El_Salvador",
      currency: "USD",
    },
    {
      value: "GQ",
      label: "Equatorial Guinea",
      timezone: "Africa/Malabo",
      currency: "XAF",
    },
    {
      value: "ER",
      label: "Eritrea",
      timezone: "Africa/Asmara",
      currency: "ERN",
    },
    {
      value: "EE",
      label: "Estonia",
      timezone: "Europe/Tallinn",
      currency: "EUR",
    },
    {
      value: "SZ",
      label: "Eswatini",
      timezone: "Africa/Mbabane",
      currency: "SZL",
    },
    {
      value: "ET",
      label: "Ethiopia",
      timezone: "Africa/Addis_Ababa",
      currency: "ETB",
    },
    {
      value: "FK",
      label: "Falkland Islands",
      timezone: "Atlantic/Stanley",
      currency: "FKP",
    },
    {
      value: "FO",
      label: "Faroe Islands",
      timezone: "Atlantic/Faroe",
      currency: "DKK",
    },
    { value: "FJ", label: "Fiji", timezone: "Pacific/Fiji", currency: "FJD" },
    {
      value: "FI",
      label: "Finland",
      timezone: "Europe/Helsinki",
      currency: "EUR",
    },
    { value: "FR", label: "France", timezone: "Europe/Paris", currency: "EUR" },
    {
      value: "GF",
      label: "French Guiana",
      timezone: "America/Cayenne",
      currency: "EUR",
    },
    {
      value: "PF",
      label: "French Polynesia",
      timezone: "Pacific/Tahiti",
      currency: "XPF",
    },
    {
      value: "TF",
      label: "French Southern Territories",
      timezone: "Indian/Kerguelen",
      currency: "EUR",
    },
    {
      value: "GA",
      label: "Gabon",
      timezone: "Africa/Libreville",
      currency: "XAF",
    },
    {
      value: "GM",
      label: "Gambia",
      timezone: "Africa/Banjul",
      currency: "GMD",
    },
    {
      value: "GE",
      label: "Georgia",
      timezone: "Asia/Tbilisi",
      currency: "GEL",
    },
    {
      value: "DE",
      label: "Germany",
      timezone: "Europe/Berlin",
      currency: "EUR",
    },
    { value: "GH", label: "Ghana", timezone: "Africa/Accra", currency: "GHS" },
    {
      value: "GI",
      label: "Gibraltar",
      timezone: "Europe/Gibraltar",
      currency: "GIP",
    },
    {
      value: "GR",
      label: "Greece",
      timezone: "Europe/Athens",
      currency: "EUR",
    },
    {
      value: "GL",
      label: "Greenland",
      timezone: "America/Godthab",
      currency: "DKK",
    },
    {
      value: "GD",
      label: "Grenada",
      timezone: "America/Grenada",
      currency: "XCD",
    },
    {
      value: "GP",
      label: "Guadeloupe",
      timezone: "America/Guadeloupe",
      currency: "EUR",
    },
    { value: "GU", label: "Guam", timezone: "Pacific/Guam", currency: "USD" },
    {
      value: "GT",
      label: "Guatemala",
      timezone: "America/Guatemala",
      currency: "GTQ",
    },
    {
      value: "GG",
      label: "Guernsey",
      timezone: "Europe/Guernsey",
      currency: "GBP",
    },
    {
      value: "GN",
      label: "Guinea",
      timezone: "Africa/Conakry",
      currency: "GNF",
    },
    {
      value: "GW",
      label: "Guinea-Bissau",
      timezone: "Africa/Bissau",
      currency: "XOF",
    },
    {
      value: "GY",
      label: "Guyana",
      timezone: "America/Guyana",
      currency: "GYD",
    },
    {
      value: "HT",
      label: "Haiti",
      timezone: "America/Port-au-Prince",
      currency: "HTG",
    },
    {
      value: "HM",
      label: "Heard & McDonald Islands",
      timezone: null,
      currency: "AUD",
    },
    {
      value: "HN",
      label: "Honduras",
      timezone: "America/Tegucigalpa",
      currency: "HNL",
    },
    {
      value: "HK",
      label: "Hong Kong SAR China",
      timezone: "Asia/Hong_Kong",
      currency: "HKD",
    },
    {
      value: "HU",
      label: "Hungary",
      timezone: "Europe/Budapest",
      currency: "HUF",
    },
    {
      value: "IS",
      label: "Iceland",
      timezone: "Atlantic/Reykjavik",
      currency: "ISK",
    },
    { value: "IN", label: "India", timezone: "Asia/Kolkata", currency: "INR" },
    {
      value: "ID",
      label: "Indonesia",
      timezone: "Asia/Jakarta",
      currency: "IDR",
    },
    { value: "IR", label: "Iran", timezone: "Asia/Tehran", currency: "IRR" },
    { value: "IQ", label: "Iraq", timezone: "Asia/Baghdad", currency: "IQD" },
    {
      value: "IE",
      label: "Ireland",
      timezone: "Europe/Dublin",
      currency: "EUR",
    },
    {
      value: "IM",
      label: "Isle of Man",
      timezone: "Europe/Isle_of_Man",
      currency: "GBP",
    },
    {
      value: "IL",
      label: "Israel",
      timezone: "Asia/Jerusalem",
      currency: "ILS",
    },
    { value: "IT", label: "Italy", timezone: "Europe/Rome", currency: "EUR" },
    {
      value: "JM",
      label: "Jamaica",
      timezone: "America/Jamaica",
      currency: "JMD",
    },
    { value: "JP", label: "Japan", timezone: "Asia/Tokyo", currency: "JPY" },
    {
      value: "JE",
      label: "Jersey",
      timezone: "Europe/Jersey",
      currency: "GBP",
    },
    { value: "JO", label: "Jordan", timezone: "Asia/Amman", currency: "JOD" },
    {
      value: "KZ",
      label: "Kazakhstan",
      timezone: "Asia/Almaty",
      currency: "KZT",
    },
    {
      value: "KE",
      label: "Kenya",
      timezone: "Africa/Nairobi",
      currency: "KES",
    },
    {
      value: "KI",
      label: "Kiribati",
      timezone: "Pacific/Tarawa",
      currency: "AUD",
    },
    { value: "KW", label: "Kuwait", timezone: "Asia/Kuwait", currency: "KWD" },
    {
      value: "KG",
      label: "Kyrgyzstan",
      timezone: "Asia/Bishkek",
      currency: "KGS",
    },
    { value: "LA", label: "Laos", timezone: "Asia/Vientiane", currency: "LAK" },
    { value: "LV", label: "Latvia", timezone: "Europe/Riga", currency: "EUR" },
    { value: "LB", label: "Lebanon", timezone: "Asia/Beirut", currency: "LBP" },
    {
      value: "LS",
      label: "Lesotho",
      timezone: "Africa/Maseru",
      currency: "LSL",
    },
    {
      value: "LR",
      label: "Liberia",
      timezone: "Africa/Monrovia",
      currency: "LRD",
    },
    {
      value: "LY",
      label: "Libya",
      timezone: "Africa/Tripoli",
      currency: "LYD",
    },
    {
      value: "LI",
      label: "Liechtenstein",
      timezone: "Europe/Vaduz",
      currency: "CHF",
    },
    {
      value: "LT",
      label: "Lithuania",
      timezone: "Europe/Vilnius",
      currency: "EUR",
    },
    {
      value: "LU",
      label: "Luxembourg",
      timezone: "Europe/Luxembourg",
      currency: "EUR",
    },
    {
      value: "MO",
      label: "Macao SAR China",
      timezone: "Asia/Macau",
      currency: "MOP",
    },
    {
      value: "MG",
      label: "Madagascar",
      timezone: "Indian/Antananarivo",
      currency: "MGA",
    },
    {
      value: "MW",
      label: "Malawi",
      timezone: "Africa/Blantyre",
      currency: "MWK",
    },
    {
      value: "MY",
      label: "Malaysia",
      timezone: "Asia/Kuala_Lumpur",
      currency: "MYR",
    },
    {
      value: "MV",
      label: "Maldives",
      timezone: "Indian/Maldives",
      currency: "MVR",
    },
    { value: "ML", label: "Mali", timezone: "Africa/Bamako", currency: "XOF" },
    { value: "MT", label: "Malta", timezone: "Europe/Malta", currency: "EUR" },
    {
      value: "MH",
      label: "Marshall Islands",
      timezone: "Pacific/Majuro",
      currency: "USD",
    },
    {
      value: "MQ",
      label: "Martinique",
      timezone: "America/Martinique",
      currency: "EUR",
    },
    {
      value: "MR",
      label: "Mauritania",
      timezone: "Africa/Nouakchott",
      currency: "MRU",
    },
    {
      value: "MU",
      label: "Mauritius",
      timezone: "Indian/Mauritius",
      currency: "MUR",
    },
    {
      value: "YT",
      label: "Mayotte",
      timezone: "Indian/Mayotte",
      currency: "EUR",
    },
    {
      value: "MX",
      label: "Mexico",
      timezone: "America/Mexico_City",
      currency: "MXN",
    },
    {
      value: "FM",
      label: "Micronesia",
      timezone: "Pacific/Chuuk",
      currency: "USD",
    },
    {
      value: "MD",
      label: "Moldova",
      timezone: "Europe/Chisinau",
      currency: "MDL",
    },
    {
      value: "MC",
      label: "Monaco",
      timezone: "Europe/Monaco",
      currency: "EUR",
    },
    {
      value: "MN",
      label: "Mongolia",
      timezone: "Asia/Ulaanbaatar",
      currency: "MNT",
    },
    {
      value: "ME",
      label: "Montenegro",
      timezone: "Europe/Podgorica",
      currency: "EUR",
    },
    {
      value: "MS",
      label: "Montserrat",
      timezone: "America/Montserrat",
      currency: "XCD",
    },
    {
      value: "MA",
      label: "Morocco",
      timezone: "Africa/Casablanca",
      currency: "MAD",
    },
    {
      value: "MZ",
      label: "Mozambique",
      timezone: "Africa/Maputo",
      currency: "MZN",
    },
    {
      value: "MM",
      label: "Myanmar (Burma)",
      timezone: "Asia/Yangon",
      currency: "MMK",
    },
    {
      value: "NA",
      label: "Namibia",
      timezone: "Africa/Windhoek",
      currency: "NAD",
    },
    { value: "NR", label: "Nauru", timezone: "Pacific/Nauru", currency: "AUD" },
    {
      value: "NP",
      label: "Nepal",
      timezone: "Asia/Kathmandu",
      currency: "NPR",
    },
    {
      value: "NL",
      label: "Netherlands",
      timezone: "Europe/Amsterdam",
      currency: "EUR",
    },
    {
      value: "NC",
      label: "New Caledonia",
      timezone: "Pacific/Noumea",
      currency: "XPF",
    },
    {
      value: "NZ",
      label: "New Zealand",
      timezone: "Pacific/Auckland",
      currency: "NZD",
    },
    {
      value: "NI",
      label: "Nicaragua",
      timezone: "America/Managua",
      currency: "NIO",
    },
    { value: "NE", label: "Niger", timezone: "Africa/Niamey", currency: "XOF" },
    {
      value: "NG",
      label: "Nigeria",
      timezone: "Africa/Lagos",
      currency: "NGN",
    },
    { value: "NU", label: "Niue", timezone: "Pacific/Niue", currency: "NZD" },
    {
      value: "NF",
      label: "Norfolk Island",
      timezone: "Pacific/Norfolk",
      currency: "AUD",
    },
    {
      value: "KP",
      label: "North Korea",
      timezone: "Asia/Pyongyang",
      currency: "KPW",
    },
    {
      value: "MK",
      label: "North Macedonia",
      timezone: "Europe/Skopje",
      currency: "MKD",
    },
    {
      value: "MP",
      label: "Northern Mariana Islands",
      timezone: "Pacific/Saipan",
      currency: "USD",
    },
    { value: "NO", label: "Norway", timezone: "Europe/Oslo", currency: "NOK" },
    { value: "OM", label: "Oman", timezone: "Asia/Muscat", currency: "OMR" },
    {
      value: "PK",
      label: "Pakistan",
      timezone: "Asia/Karachi",
      currency: "PKR",
    },
    { value: "PW", label: "Palau", timezone: "Pacific/Palau", currency: "USD" },
    {
      value: "PS",
      label: "Palestinian Territories",
      timezone: "Asia/Gaza",
      currency: "ILS",
    },
    {
      value: "PA",
      label: "Panama",
      timezone: "America/Panama",
      currency: "PAB",
    },
    {
      value: "PG",
      label: "Papua New Guinea",
      timezone: "Pacific/Port_Moresby",
      currency: "PGK",
    },
    {
      value: "PY",
      label: "Paraguay",
      timezone: "America/Asuncion",
      currency: "PYG",
    },
    { value: "PE", label: "Peru", timezone: "America/Lima", currency: "PEN" },
    {
      value: "PH",
      label: "Philippines",
      timezone: "Asia/Manila",
      currency: "PHP",
    },
    {
      value: "PN",
      label: "Pitcairn Islands",
      timezone: "Pacific/Pitcairn",
      currency: "NZD",
    },
    {
      value: "PL",
      label: "Poland",
      timezone: "Europe/Warsaw",
      currency: "PLN",
    },
    {
      value: "PT",
      label: "Portugal",
      timezone: "Europe/Lisbon",
      currency: "EUR",
    },
    {
      value: "PR",
      label: "Puerto Rico",
      timezone: "America/Puerto_Rico",
      currency: "USD",
    },
    { value: "QA", label: "Qatar", timezone: "Asia/Qatar", currency: "QAR" },
    {
      value: "RE",
      label: "Réunion",
      timezone: "Indian/Reunion",
      currency: "EUR",
    },
    {
      value: "RO",
      label: "Romania",
      timezone: "Europe/Bucharest",
      currency: "RON",
    },
    {
      value: "RU",
      label: "Russia",
      timezone: "Europe/Moscow",
      currency: "RUB",
    },
    {
      value: "RW",
      label: "Rwanda",
      timezone: "Africa/Kigali",
      currency: "RWF",
    },
    { value: "WS", label: "Samoa", timezone: "Pacific/Apia", currency: "WST" },
    {
      value: "SM",
      label: "San Marino",
      timezone: "Europe/San_Marino",
      currency: "EUR",
    },
    {
      value: "ST",
      label: "São Tomé & Príncipe",
      timezone: "Africa/Sao_Tome",
      currency: "STN",
    },
    {
      value: "SA",
      label: "Saudi Arabia",
      timezone: "Asia/Riyadh",
      currency: "SAR",
    },
    {
      value: "SN",
      label: "Senegal",
      timezone: "Africa/Dakar",
      currency: "XOF",
    },
    {
      value: "RS",
      label: "Serbia",
      timezone: "Europe/Belgrade",
      currency: "RSD",
    },
    {
      value: "SC",
      label: "Seychelles",
      timezone: "Indian/Mahe",
      currency: "SCR",
    },
    {
      value: "SL",
      label: "Sierra Leone",
      timezone: "Africa/Freetown",
      currency: "SLL",
    },
    {
      value: "SG",
      label: "Singapore",
      timezone: "Asia/Singapore",
      currency: "SGD",
    },
    {
      value: "SX",
      label: "Sint Maarten",
      timezone: "America/Lower_Princes",
      currency: "ANG",
    },
    {
      value: "SK",
      label: "Slovakia",
      timezone: "Europe/Bratislava",
      currency: "EUR",
    },
    {
      value: "SI",
      label: "Slovenia",
      timezone: "Europe/Ljubljana",
      currency: "EUR",
    },
    {
      value: "SB",
      label: "Solomon Islands",
      timezone: "Pacific/Honiara",
      currency: "SBD",
    },
    {
      value: "SO",
      label: "Somalia",
      timezone: "Africa/Mogadishu",
      currency: "SOS",
    },
    {
      value: "ZA",
      label: "South Africa",
      timezone: "Africa/Johannesburg",
      currency: "ZAR",
    },
    {
      value: "GS",
      label: "South Georgia & South Sandwich Islands",
      timezone: "Atlantic/South_Georgia",
      currency: "GBP",
    },
    {
      value: "KR",
      label: "South Korea",
      timezone: "Asia/Seoul",
      currency: "KRW",
    },
    {
      value: "SS",
      label: "South Sudan",
      timezone: "Africa/Juba",
      currency: "SSP",
    },
    { value: "ES", label: "Spain", timezone: "Europe/Madrid", currency: "EUR" },
    {
      value: "LK",
      label: "Sri Lanka",
      timezone: "Asia/Colombo",
      currency: "LKR",
    },
    {
      value: "BL",
      label: "St. Barthélemy",
      timezone: "America/St_Barthelemy",
      currency: "EUR",
    },
    {
      value: "SH",
      label: "St. Helena",
      timezone: "Atlantic/St_Helena",
      currency: "SHP",
    },
    {
      value: "KN",
      label: "St. Kitts & Nevis",
      timezone: "America/St_Kitts",
      currency: "XCD",
    },
    {
      value: "LC",
      label: "St. Lucia",
      timezone: "America/St_Lucia",
      currency: "XCD",
    },
    {
      value: "MF",
      label: "St. Martin",
      timezone: "America/Marigot",
      currency: "EUR",
    },
    {
      value: "PM",
      label: "St. Pierre & Miquelon",
      timezone: "America/Miquelon",
      currency: "EUR",
    },
    {
      value: "VC",
      label: "St. Vincent & Grenadines",
      timezone: "America/St_Vincent",
      currency: "XCD",
    },
    {
      value: "SD",
      label: "Sudan",
      timezone: "Africa/Khartoum",
      currency: "SDG",
    },
    {
      value: "SR",
      label: "Suriname",
      timezone: "America/Paramaribo",
      currency: "SRD",
    },
    {
      value: "SJ",
      label: "Svalbard & Jan Mayen",
      timezone: "Arctic/Longyearbyen",
      currency: "NOK",
    },
    {
      value: "SE",
      label: "Sweden",
      timezone: "Europe/Stockholm",
      currency: "SEK",
    },
    {
      value: "CH",
      label: "Switzerland",
      timezone: "Europe/Zurich",
      currency: "CHF",
    },
    { value: "SY", label: "Syria", timezone: "Asia/Damascus", currency: "SYP" },
    { value: "TW", label: "Taiwan", timezone: "Asia/Taipei", currency: "TWD" },
    {
      value: "TJ",
      label: "Tajikistan",
      timezone: "Asia/Dushanbe",
      currency: "TJS",
    },
    {
      value: "TZ",
      label: "Tanzania",
      timezone: "Africa/Dar_es_Salaam",
      currency: "TZS",
    },
    {
      value: "TH",
      label: "Thailand",
      timezone: "Asia/Bangkok",
      currency: "THB",
    },
    {
      value: "TL",
      label: "Timor-Leste",
      timezone: "Asia/Dili",
      currency: "USD",
    },
    { value: "TG", label: "Togo", timezone: "Africa/Lome", currency: "XOF" },
    {
      value: "TK",
      label: "Tokelau",
      timezone: "Pacific/Fakaofo",
      currency: "NZD",
    },
    {
      value: "TO",
      label: "Tonga",
      timezone: "Pacific/Tongatapu",
      currency: "TOP",
    },
    {
      value: "TT",
      label: "Trinidad & Tobago",
      timezone: "America/Port_of_Spain",
      currency: "TTD",
    },
    {
      value: "TN",
      label: "Tunisia",
      timezone: "Africa/Tunis",
      currency: "TND",
    },
    {
      value: "TR",
      label: "Turkey",
      timezone: "Europe/Istanbul",
      currency: "TRY",
    },
    {
      value: "TM",
      label: "Turkmenistan",
      timezone: "Asia/Ashgabat",
      currency: "TMT",
    },
    {
      value: "TC",
      label: "Turks & Caicos Islands",
      timezone: "America/Grand_Turk",
      currency: "USD",
    },
    {
      value: "TV",
      label: "Tuvalu",
      timezone: "Pacific/Funafuti",
      currency: "AUD",
    },
    {
      value: "UM",
      label: "U.S. Outlying Islands",
      timezone: "Pacific/Midway",
      currency: "USD",
    },
    {
      value: "VI",
      label: "U.S. Virgin Islands",
      timezone: "America/St_Thomas",
      currency: "USD",
    },
    {
      value: "UG",
      label: "Uganda",
      timezone: "Africa/Kampala",
      currency: "UGX",
    },
    { value: "UA", label: "Ukraine", timezone: "Europe/Kiev", currency: "UAH" },
    {
      value: "AE",
      label: "United Arab Emirates",
      timezone: "Asia/Dubai",
      currency: "AED",
    },
    {
      value: "GB",
      label: "United Kingdom",
      timezone: "Europe/London",
      currency: "GBP",
    },
    {
      value: "US",
      label: "United States",
      timezone: "America/New_York",
      currency: "USD",
    },
    {
      value: "UY",
      label: "Uruguay",
      timezone: "America/Montevideo",
      currency: "UYU",
    },
    {
      value: "UZ",
      label: "Uzbekistan",
      timezone: "Asia/Tashkent",
      currency: "UZS",
    },
    {
      value: "VU",
      label: "Vanuatu",
      timezone: "Pacific/Efate",
      currency: "VUV",
    },
    {
      value: "VA",
      label: "Vatican City",
      timezone: "Europe/Vatican",
      currency: "EUR",
    },
    {
      value: "VE",
      label: "Venezuela",
      timezone: "America/Caracas",
      currency: "VES",
    },
    {
      value: "VN",
      label: "Vietnam",
      timezone: "Asia/Ho_Chi_Minh",
      currency: "VND",
    },
    {
      value: "WF",
      label: "Wallis & Futuna",
      timezone: "Pacific/Wallis",
      currency: "XPF",
    },
    {
      value: "EH",
      label: "Western Sahara",
      timezone: "Africa/El_Aaiun",
      currency: "MAD",
    },
    { value: "YE", label: "Yemen", timezone: "Asia/Aden", currency: "YER" },
    {
      value: "ZM",
      label: "Zambia",
      timezone: "Africa/Lusaka",
      currency: "ZMW",
    },
    {
      value: "ZW",
      label: "Zimbabwe",
      timezone: "Africa/Harare",
      currency: "ZWL",
    },
  ];
