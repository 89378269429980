import {
  createContext,
  useContext,
  useState,
  type Dispatch,
  type SetStateAction,
} from "react";

import type { SelectOption } from "components/forms/mui/FormikSelect";

export type ISelectedOrganisationTopBar = {
  isSubsidiary: boolean;
} & SelectOption;

export type SubsidiaryOrganisationType = {
  selectedOrganisation: ISelectedOrganisationTopBar | undefined | null;
  setSelectedOrganisation: Dispatch<
    SetStateAction<ISelectedOrganisationTopBar | undefined | null>
  >;
};

const SubsidiaryOrganisationFilterContext = createContext<
  SubsidiaryOrganisationType | undefined
>(undefined);

type SubsidiaryOrganisationProviderProps = { children: React.ReactNode };

const SubsidiaryOrganisationProvider = ({
  children,
}: SubsidiaryOrganisationProviderProps) => {
  const [selectedOrganisation, setSelectedOrganisation] = useState<
    ISelectedOrganisationTopBar | undefined | null
  >();

  return (
    <SubsidiaryOrganisationFilterContext.Provider
      value={{
        selectedOrganisation,
        setSelectedOrganisation,
      }}
    >
      {children}
    </SubsidiaryOrganisationFilterContext.Provider>
  );
};

function useSubsidiaryOrganisationFilterContext() {
  const context = useContext(SubsidiaryOrganisationFilterContext);
  if (context === undefined) {
    throw new Error(
      "useSubsidiaryOrganisationFilterContext must be used within a SubsidiaryOrganisationProvider",
    );
  }
  return context;
}

export {
  SubsidiaryOrganisationFilterContext,
  SubsidiaryOrganisationProvider,
  useSubsidiaryOrganisationFilterContext,
};
